
import './App.css';
import Header from './mycomp/Header'
import Bodyap from './mycomp/Bodyap'
import Footer from './mycomp/Footer'

function App() {
  return (
    <>
      <Header />
      <Bodyap />
      <Footer />
    </>
    
  );
  



// search.onclick = function()
// {
//   search.classList.toggle("active")
// }
}
export default App;

